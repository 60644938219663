import React, { createContext, useContext, useEffect, useReducer, useState } from 'react'
import PropTypes from 'prop-types'
import { getDataFromAPI, postDataToAPI } from 'helpers/api'
import { useAccountContext } from 'context/Account'
import { initialState, LocationReducer } from '../reducers/locationReducer'
import { toast } from 'react-toastify'
import * as demoData from 'data/demo'

export const LocationContext = createContext()
export function LocationProvider ({ children }) {
  const { appState } = useAccountContext()
  const [locationState, dispatch] = useReducer(LocationReducer, initialState)
  const [readyState, setReadyState] = useState(false)
  const [locations, setLocations] = useState(null)
  const [company, setCompany] = useState(null)
  const [kiosks, setKiosks] = useState(null)

  useEffect(() => {
    if (
      appState.ready
      && appState.authenticated
      && appState.attributes !== null
      && Object.prototype.hasOwnProperty.call(appState.attributes, 'custom:companyid')) {
      setReadyState(appState.ready)
    }

    if (process.env.REACT_APP_ENV === 'demo') {
      setReadyState(true)
    }
  }, [appState])

  useEffect(() => {
    if (readyState === true) {
      getCompanyInfo()
      getKiosksForLocations()
    }
  }, [readyState])

  useEffect(() => {
    if (locationState.locations !== null && kiosks === null) {
      getKiosksForLocations()
    }
  }, [locationState.locations, locations])

  useEffect(() => {
    if (readyState === true && locationState.active_view !== null) {
      if (locationState.active_view.type === 'l') {
        getLocation(locationState.active_view.id)
      } else {
        getCompanyInfo()
      }
    }
  }, [locationState.active_view, readyState])

  useEffect(() => {
    if (company !== null) {
      if (locationState.active_view.id !== 0 && !getViewObject(locationState.active_view.id)) {
        setActiveViewSet()
      }
    }
  }, [company])

  const getViewObject = (id) => {
    let current = {}
    if (company !== null) {
      current = company.reduce((acc, cur) => {
        if (cur.companyid === parseInt(id)) {
          return {
            id: cur.companyid,
            name: cur.companylegalname,
            type: 'c'
          }
        }
        return acc
      }, {})

      if (JSON.stringify(current) === '{}') {
        current = company.map(c => {
          const loc = c.locations.reduce((acc, cur) => {
            if (cur.location_id === parseInt(id)) {
              return {
                id: cur.location_id,
                name: cur.location_name,
                type: 'l'
              }
            }
            return acc
          }, {})

          if (JSON.stringify(loc) !== '{}') {
            return loc
          } else {
            return false
          }
        }).filter(x => {
          return x !== false
        })[0]
      }
    }

    return current
  }

  async function getCompanyInfo () {
    if (process.env.REACT_APP_ENV !== 'demo') {
      const details = await getDataFromAPI('/companys')
      if (details !== undefined && details.status === 200) {
        setCompany(details.data)
        dispatch({
          type: 'UPDATE_COMPANY',
          payload: {
            company: details.data,
          },
        })

        if (locationState.active_view === null) {
          setActiveViewSet()
        }
      }
    } else {
      setCompany(demoData.companies)
      dispatch({
        type: 'UPDATE_COMPANY',
        payload: {
          company: demoData.companies,
        },
      })

      if (locationState.active_view === null) {
        setActiveViewSet()
      }
    }
  }

  /**
   * Gets relevant Kiosk IDs for all locations the user is allowed to access.
   * Uses User Attributes to base query on Company ID and ensure a user can not
   * alter the request to fetch unauthorized Kiosk data.
   */
  async function getKiosksForLocations () {
    if (process.env.REACT_APP_ENV !== 'demo') {
      const details = await getDataFromAPI('/companys/kiosks')
      if (details !== undefined && details.status === 200) {
        setKiosks(details.data)
        dispatch({
          type: 'UPDATE_KIOSKS',
          payload: {
            kiosks: details.data,
          },
        })
      }
    } else {
      setKiosks(demoData.kiosks)
      dispatch({
        type: 'UPDATE_KIOSKS',
        payload: {
          kiosks: demoData.kiosks,
        },
      })
    }
  }

  async function getLocation (id) {
    if (process.env.REACT_APP_ENV !== 'demo') {
      const details = await getDataFromAPI('/location/' + id)
      if (details !== undefined && details.status === 200) {
        dispatch({
          type: 'UPDATE_ACTIVE_LOCATION',
          payload: {
            active_location: details.data,
          },
        })
      }
    } else {
      dispatch({
        type: 'UPDATE_ACTIVE_LOCATION',
        payload: {
          active_location: demoData.companies[0].locations.filter(l => {
            if (l.location_id === id) {
              return true
            }
            return false
          })[0],
        },
      })
    }
  }

  async function getLocationDfsSummary (id) {
    if (process.env.REACT_APP_ENV !== 'demo') {
      const details = await getDataFromAPI('/location/' + id + '/dfs/summary')
      if (details !== undefined && details.status === 200) {
        dispatch({
          type: 'UPDATE_ACTIVE_LOCATION_DFS_SUMMARY',
          payload: {
            dfs_summary: details.data,
          },
        })
      }
    }
  }

  function setActiveViewSet (id) {
    let obj
    if (id === undefined || id === null || parseInt(id) < 1) {
      obj = {
        id: 0,
        name: 'All Companies',
        type: 'u'
      }
    } else {
      obj = getViewObject(id)
    }

    dispatch({
      type: 'ACTIVE_VIEW_SET',
      payload: {
        view: obj,
      },
    })

    localStorage.setItem('activeViewSet', JSON.stringify(obj))
  }

  /**
   * Given an array of location ids and a month and a year as parameters, will
   * dfs redemptions to get a list of gallons per day for the specified time-frame.
   */
  async function getRewardsPerDayData (locations, month, year) {
    let result = null

    const startDate = new Date(year, month, 1).toISOString().split('T')[0]
    const endDate = new Date(year, month + 1, 0).toISOString().split('T')[0]

    const payload = {
      locations: locations,
      dateRange: {
        start: startDate,
        end: endDate
      }
    }

    if (process.env.REACT_APP_ENV !== 'demo') {
      const details = await postDataToAPI('rewards/pointsperday', payload)
      if (details !== undefined && details.status === 200) {
        result = details.data
      }
    } else {
      result = demoData.pointsPerDay(month, year)
    }

    return result
  }

  /**
   * Given an array of location ids and a month and a year as parameters, will
   * dfs redemptions to get a list of gallons per day for the specified time-frame.
   */
  async function getDFSGallonsPerDayData (aLocations, sMonth, sYear) {
    let aReturn = null

    const sStartDate = new Date(sYear, sMonth, 1).toISOString().split('T')[0]
    const sEndDate = new Date(sYear, sMonth + 1, 0).toISOString().split('T')[0]

    const oPayload = {
      locations: aLocations,
      dateRange: {
        start: sStartDate,
        end: sEndDate
      }
    }

    if (process.env.REACT_APP_ENV !== 'demo') {
      const oDetails = await postDataToAPI('dfs/gallonsperday', oPayload)
      if (oDetails !== undefined && oDetails.status === 200) {
        aReturn = oDetails.data
      }
    } else {
      aReturn = demoData.gallonsPerDay(sMonth, sYear)
    }

    return aReturn
  }

  /**
   * Given an array of location ids and potentially a start and/or end date as parameters, will
   * dfs redemptions to get a list of gallons per month for the specified time-frame.
   */
  async function getDFSGallonsPerMonthData (locations) {
    let result = null;

    const oPayload = {
      locations: locations,
    }

    if (process.env.REACT_APP_ENV !== 'demo') {
      const details = await postDataToAPI('dfs/gallonspermonth', oPayload)
      if (details !== undefined && details.status === 200) {
        result = details.data
      }
    } else {
      result = demoData.gallonsPerMonth()
    }

    return result
  }

  /**
   * Given an array of location ids and potentially a date as parameters, will
   * return a list of competitors and their pricings
   */
  async function getCompetitorData (locations, date) {
    let result = null;

    const targetDate = new Date(date).toISOString().split('T')[0];

    const payload = {
      locations: locations,
      date: targetDate,
    }

    if (process.env.REACT_APP_ENV !== 'demo') {
      const details = await postDataToAPI('reports/competitors', payload)
      if (details !== undefined && details.status === 200) {
        result = details.data
      }
    } else {
      result = demoData.competitorPrices()
    }

    return result
  }

  /**
   * Given an array of location ids and an array potential filters, will query for DFS
   * redemption/reservation data and return a result set.
   * @param aLocations
   * @param aFilters
   * @returns {Promise<void>}
   */
  async function getDFSData (aLocations, aFilters) {
    let aReturn = null

    const oPayload = {
      locations: aLocations,
      ...aFilters
    }

    if (process.env.REACT_APP_ENV !== 'demo') {
      const oDetails = await postDataToAPI('/dfs/transactions', oPayload)
      if (oDetails !== undefined && oDetails.status === 200) {
        aReturn = oDetails.data
      }
    } else {
      aReturn = demoData.getTransactions(aFilters.reportType)
    }
    return aReturn
  }

  /**
   * Given an array of location ids and an array potential filters, will query for terminal
   * device data and return a result set.
   * @param aLocations
   * @param aFilters
   * @returns {Promise<null>}
   */
  async function getTerminalDevices (aLocations, aFilters) {
    let aReturn = null

    const oPayload = {
      locations: aLocations,
      ...aFilters
    }

    if (process.env.REACT_APP_ENV !== 'demo') {
      const oDetails = await postDataToAPI('/terminaldevices/list', oPayload)
      if (oDetails !== undefined && oDetails.status === 200) {
        aReturn = oDetails.data
      }
    } else {
      aReturn = demoData.getTerminalDevices(aLocations, aFilters.reportType)
    }
    return aReturn
  }

  /**
   * Given a device and a placement, will update the device's placement in the database.
   * @param aUpdates
   * @returns {Promise<void>}
   */
  async function editDeviceRequest (aUpdates) {
    const oPayload = {
      ...aUpdates
    }

    return await postDataToAPI('/terminaldevices/update', oPayload)
  }

  async function setKioskRetailEdit (locationId, retailEdit) {
    if (process.env.REACT_APP_ENV !== 'demo') {
      const details = await postDataToAPI('/location/' + locationId, { kioskretailedit: (retailEdit ? 1 : 0) })

      // if details are not undefined and status equals 200
      if (details?.status === 200) {
        // update the company/location data
        getCompanyInfo()
        // update the kiosks
        getKiosksForLocations()
        // send a message
        toast.success('Kiosk retail edit setting successfully updated')
      } else {
        toast.error('There was an error updating the kiosk retail edit setting')
      }
    } else {
      toast.success('Kiosk retail edit setting successfully updated')
    }
  }

  async function setLocationRetailPrice (locationId, retailPrice) {
    if (process.env.REACT_APP_ENV !== 'demo') {
      const details = await postDataToAPI('/location/' + locationId + '/pricing', { retail_price: parseFloat(retailPrice) })

      // if details are not undefined and status equals 200
      if (details?.status === 200) {
        // update the company/location data
        await getCompanyInfo()
        // send a message
        toast.success('Retail price successfully updated or confirmed')
      } else {
        toast.error('There was an error updating the retail price')
      }
    } else {
      toast.success('Retail price successfully updated or confirmed')
    }
  }

  async function setLocationPrices (locationId, retailPrice, defPrice) {
    if (process.env.REACT_APP_ENV !== 'demo') {
      // create the payload
      const oPayload = {
        retail_price: parseFloat(retailPrice)
      };

      // if defPrice is not null, add it to the payload
      if (defPrice !== null && defPrice !== '') {
        oPayload.retail_price_def = parseFloat(defPrice)
      }

      const details = await postDataToAPI('/location/' + locationId + '/pricing', oPayload)

      // if details are not undefined and status equals 200
      if (details?.status === 200) {
        // update the company/location data
        await getCompanyInfo()
        // send a message
        toast.success('Prices successfully updated or confirmed')
      } else {
        toast.error('There was an error updating the prices')
      }
    } else {
      toast.success('Price successfully updated or confirmed')
    }
  }

  async function setActiveLocationRetailPrice (retailPrice) {
    await setLocationRetailPrice(locationState.active_view.id, retailPrice)
  }

  const sharedState = {
    locationState,
    getDFSData,
    getDFSGallonsPerDayData,
    getDFSGallonsPerMonthData,
    getCompetitorData,
    getRewardsPerDayData,
    setLocationRetailPrice,
    setLocationPrices,
    setKioskRetailEdit,
    setActiveLocationRetailPrice,
    setActiveViewSet,
    getTerminalDevices,
    editDeviceRequest,
  }

  return (
    <LocationContext.Provider value={sharedState}>
      {children}
    </LocationContext.Provider>
  )
}

export function useLocationContext () {
  const state = useContext(LocationContext)

  if (state === undefined) {
    throw new Error('useLocationContext must be used within a LocationProvider')
  }

  return state
}

LocationProvider.propTypes = {
  children: PropTypes.any,
}
